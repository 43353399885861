import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, forwardRef, Directive, Inject, Input, Output, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
class FroalaEditorDirective {
  zone;
  platformId;
  // editor options
  _opts = {
    immediateAngularModelUpdate: false,
    angularIgnoreAttrs: null
  };
  _element;
  SPECIAL_TAGS = ['img', 'button', 'input', 'a'];
  INNER_HTML_ATTR = 'innerHTML';
  _hasSpecialTag = false;
  // editor element
  _editor;
  // initial editor content
  _model;
  _editorInitialized = false;
  _oldModel = null;
  constructor(el, zone, platformId) {
    this.zone = zone;
    this.platformId = platformId;
    let element = el.nativeElement;
    // check if the element is a special tag
    if (this.SPECIAL_TAGS.indexOf(element.tagName.toLowerCase()) != -1) {
      this._hasSpecialTag = true;
    }
    this._element = element;
    this.zone = zone;
  }
  // Begin ControlValueAccesor methods.
  onChange = _ => {};
  onTouched = () => {};
  // Form model content changed.
  writeValue(content) {
    this.updateEditor(content);
    if (content) {
      this.setup();
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  // End ControlValueAccesor methods.
  // froalaEditor directive as input: store the editor options
  set froalaEditor(opts) {
    this._opts = this.clone(opts || this._opts);
    this._opts = {
      ...this._opts
    };
  }
  // TODO: replace clone method with better possible alternate 
  clone(item) {
    const me = this;
    if (!item) {
      return item;
    } // null, undefined values check
    let types = [Number, String, Boolean],
      result;
    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function (type) {
      if (item instanceof type) {
        result = type(item);
      }
    });
    if (typeof result == "undefined") {
      if (Object.prototype.toString.call(item) === "[object Array]") {
        result = [];
        item.forEach(function (child, index, array) {
          result[index] = me.clone(child);
        });
      } else if (typeof item == "object") {
        // testing that this is DOM
        if (item.nodeType && typeof item.cloneNode == "function") {
          result = item.cloneNode(true);
        } else if (!item.prototype) {
          // check that this is a literal
          if (item instanceof Date) {
            result = new Date(item);
          } else {
            // it is an object literal
            result = {};
            for (var i in item) {
              result[i] = me.clone(item[i]);
            }
          }
        } else {
          if (false && item.constructor) {
            result = new item.constructor();
          } else {
            result = item;
          }
        }
      } else {
        result = item;
      }
    }
    return result;
  }
  // froalaModel directive as input: store initial editor content
  set froalaModel(content) {
    this.updateEditor(content);
  }
  stringify(obj) {
    let cache = [];
    let str = JSON.stringify(obj, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }
  // Update editor with model contents.
  updateEditor(content) {
    if (this.stringify(this._oldModel) == this.stringify(content)) {
      return;
    }
    if (!this._hasSpecialTag) {
      this._oldModel = content;
    } else {
      this._model = content;
    }
    if (this._editorInitialized) {
      if (!this._hasSpecialTag) {
        this._editor.html.set(content);
      } else {
        this.setContent();
      }
    } else {
      if (!this._hasSpecialTag) {
        this._element.innerHTML = content || '';
      } else {
        this.setContent();
      }
    }
  }
  // froalaModel directive as output: update model if editor contentChanged
  froalaModelChange = new EventEmitter();
  // froalaInit directive as output: send manual editor initialization
  froalaInit = new EventEmitter();
  // update model if editor contentChanged
  updateModel() {
    this.zone.run(() => {
      let modelContent = null;
      if (this._hasSpecialTag) {
        let attributeNodes = this._element.attributes;
        let attrs = {};
        for (let i = 0; i < attributeNodes.length; i++) {
          let attrName = attributeNodes[i].name;
          if (this._opts.angularIgnoreAttrs && this._opts.angularIgnoreAttrs.indexOf(attrName) != -1) {
            continue;
          }
          attrs[attrName] = attributeNodes[i].value;
        }
        if (this._element.innerHTML) {
          attrs[this.INNER_HTML_ATTR] = this._element.innerHTML;
        }
        modelContent = attrs;
      } else {
        let returnedHtml = this._editor.html.get();
        if (typeof returnedHtml === 'string') {
          modelContent = returnedHtml;
        }
      }
      if (this._oldModel !== modelContent) {
        this._oldModel = modelContent;
        // Update froalaModel.
        this.froalaModelChange.emit(modelContent);
        // Update form model.
        this.onChange(modelContent);
      }
    });
  }
  registerEvent(eventName, callback) {
    if (!eventName || !callback) {
      return;
    }
    if (!this._opts.events) {
      this._opts.events = {};
    }
    this._opts.events[eventName] = callback;
  }
  initListeners() {
    let self = this;
    // Check if we have events on the editor.
    if (this._editor.events) {
      // bind contentChange and keyup event to froalaModel
      this._editor.events.on('contentChanged', function () {
        self.updateModel();
      });
      this._editor.events.on('mousedown', function () {
        setTimeout(function () {
          self.onTouched();
        }, 0);
      });
      if (this._opts.immediateAngularModelUpdate) {
        this._editor.events.on('keyup', function () {
          setTimeout(function () {
            self.updateModel();
          }, 0);
        });
      }
    }
    this._editorInitialized = true;
  }
  createEditor() {
    if (this._editorInitialized) {
      return;
    }
    this.setContent(true);
    // init editor
    this.zone.runOutsideAngular(() => {
      // Add listeners on initialized event.
      if (!this._opts.events) this._opts.events = {};
      // Register initialized event.
      this.registerEvent('initialized', this._opts.events && this._opts.events.initialized);
      const existingInitCallback = this._opts.events.initialized;
      // Default initialized event.
      if (!this._opts.events.initialized || !this._opts.events.initialized.overridden) {
        this._opts.events.initialized = () => {
          this.initListeners();
          existingInitCallback && existingInitCallback.call(this._editor, this);
        };
        this._opts.events.initialized.overridden = true;
      }
      import('froala-editor').then(({
        default: FroalaEditor
      }) => {
        // Initialize the Froala Editor.
        this._editor = new FroalaEditor(this._element, this._opts);
      });
    });
  }
  setHtml() {
    this._editor.html.set(this._model || "");
    // This will reset the undo stack everytime the model changes externally. Can we fix this?
    this._editor.undo.reset();
    this._editor.undo.saveStep();
  }
  setContent(firstTime = false) {
    let self = this;
    // Set initial content
    if (this._model || this._model == '') {
      this._oldModel = this._model;
      if (this._hasSpecialTag) {
        let tags = this._model;
        // add tags on element
        if (tags) {
          for (let attr in tags) {
            if (tags.hasOwnProperty(attr) && attr != this.INNER_HTML_ATTR) {
              this._element.setAttribute(attr, tags[attr]);
            }
          }
          if (tags.hasOwnProperty(this.INNER_HTML_ATTR)) {
            this._element.innerHTML = tags[this.INNER_HTML_ATTR];
          }
        }
      } else {
        if (firstTime) {
          this.registerEvent('initialized', function () {
            self.setHtml();
          });
        } else {
          self.setHtml();
        }
      }
    }
  }
  destroyEditor() {
    if (this._editorInitialized) {
      this._editor.destroy();
      this._editorInitialized = false;
    }
  }
  getEditor() {
    if (this._element) {
      return this._editor;
    }
    return null;
  }
  // send manual editor initialization
  generateManualController() {
    let controls = {
      initialize: this.createEditor.bind(this),
      destroy: this.destroyEditor.bind(this),
      getEditor: this.getEditor.bind(this)
    };
    this.froalaInit.emit(controls);
  }
  // TODO not sure if ngOnInit is executed after @inputs
  ngAfterViewInit() {
    // check if output froalaInit is present. Maybe observers is private and should not be used?? TODO how to better test that an output directive is present.
    // Only allow initialization in browser
    if (isPlatformBrowser(this.platformId)) {
      this.setup();
    }
  }
  initialized = false;
  setup() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (!this.froalaInit.observers.length) {
      this.createEditor();
    } else {
      this.generateManualController();
    }
  }
  ngOnDestroy() {
    this.destroyEditor();
  }
  setDisabledState(isDisabled) {}
  /** @nocollapse */
  static ɵfac = function FroalaEditorDirective_Factory(t) {
    return new (t || FroalaEditorDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PLATFORM_ID));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: FroalaEditorDirective,
    selectors: [["", "froalaEditor", ""]],
    inputs: {
      froalaEditor: "froalaEditor",
      froalaModel: "froalaModel"
    },
    outputs: {
      froalaModelChange: "froalaModelChange",
      froalaInit: "froalaInit"
    },
    exportAs: ["froalaEditor"],
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FroalaEditorDirective),
      multi: true
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FroalaEditorDirective, [{
    type: Directive,
    args: [{
      selector: '[froalaEditor]',
      exportAs: 'froalaEditor',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FroalaEditorDirective),
        multi: true
      }],
      standalone: false
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    froalaEditor: [{
      type: Input
    }],
    froalaModel: [{
      type: Input
    }],
    froalaModelChange: [{
      type: Output
    }],
    froalaInit: [{
      type: Output
    }]
  });
})();
class FroalaEditorModule {
  static forRoot() {
    return {
      ngModule: FroalaEditorModule,
      providers: []
    };
  }
  /** @nocollapse */
  static ɵfac = function FroalaEditorModule_Factory(t) {
    return new (t || FroalaEditorModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FroalaEditorModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FroalaEditorModule, [{
    type: NgModule,
    args: [{
      declarations: [FroalaEditorDirective],
      exports: [FroalaEditorDirective]
    }]
  }], null, null);
})();
class FroalaViewDirective {
  renderer;
  _element;
  constructor(renderer, element) {
    this.renderer = renderer;
    this._element = element.nativeElement;
  }
  // update content model as it comes
  set froalaView(content) {
    this._element.innerHTML = content;
  }
  ngAfterViewInit() {
    this.renderer.addClass(this._element, "fr-view");
  }
  /** @nocollapse */
  static ɵfac = function FroalaViewDirective_Factory(t) {
    return new (t || FroalaViewDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: FroalaViewDirective,
    selectors: [["", "froalaView", ""]],
    inputs: {
      froalaView: "froalaView"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FroalaViewDirective, [{
    type: Directive,
    args: [{
      selector: '[froalaView]',
      standalone: false
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    froalaView: [{
      type: Input
    }]
  });
})();
class FroalaViewModule {
  static forRoot() {
    return {
      ngModule: FroalaViewModule,
      providers: []
    };
  }
  /** @nocollapse */
  static ɵfac = function FroalaViewModule_Factory(t) {
    return new (t || FroalaViewModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FroalaViewModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FroalaViewModule, [{
    type: NgModule,
    args: [{
      declarations: [FroalaViewDirective],
      exports: [FroalaViewDirective]
    }]
  }], null, null);
})();
class FERootModule {
  /** @nocollapse */static ɵfac = function FERootModule_Factory(t) {
    return new (t || FERootModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FERootModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(), FroalaEditorModule, FroalaViewModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FERootModule, [{
    type: NgModule,
    args: [{
      imports: [FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()],
      exports: [FroalaEditorModule, FroalaViewModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FERootModule, FroalaEditorDirective, FroalaEditorModule, FroalaViewDirective, FroalaViewModule };
